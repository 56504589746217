import { Auth } from "aws-amplify";
import pptxgen from "pptxgenjs";
import React, { useContext, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { IconContext } from "react-icons";
import { CgColorPicker } from "react-icons/cg";
import { FaFilter } from "react-icons/fa";
import { FiDownload, FiLogOut } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";

import {
  CHART_SUMMARY_TITLE,
  COLORS_RYGU,
  DATA_LABEL_FONT_FACE,
  DATA_LABEL_FONT_SIZE,
  LEGEND_FONT_FACE,
  LINE_CHART_BAR_DATA,
  LINE_CHART_LABELS,
  LINE_CHART_LIST,
  MANUFACTURER_PERFERENCE_Q8_LIST,
  MANUFACTURER_PREFERENCE_BAR_CHART_DATA,
  QUESTION_1,
  QUESTION_2,
  QUESTION_3,
  QUESTION_4,
  QUESTION_5,
  QUESTION_6,
  QUESTION_7,
  QUESTION_8,
  QUESTION_9,
  RATING_PRODUCT_FEATURE_BAR_CHART_DATA,
  RATING_PRODUCT_FEATURE_LABELS,
  RATING_PRODUCT_FEATURE_LIST,
  SALES_BY_REGION_DATA,
  SATISFACTION_LEVEL_DATA,
  SATISFACTION_LEVEL_LIST,
  SOFTWARE_ADOPTION_BAR_CHART_DATA,
  SOFTWARE_ADOPTION_LABELS,
  SOFTWARE_ADOPTION_LIST,
  SWITCHING_MANUFACTURER_BAR_CHART_DATA,
  SWITCHING_MANUFACTURER_Q9_LIST,
  TITLE_FONT_FACE,
  TITLE_FONT_SIZE,
} from "../constants/constants";

import {
  ColorPaletteContext,
  FilterContext,
  FilteredQuestionListContext,
} from "../App";
import ColorPickerModal from "./Modal/ColorPickerModal";
import FilterModal from "./Modal/FilterModal";

export default function Navigation() {
  const colorPaletteContext = useContext(ColorPaletteContext);
  const filterContext = useContext(FilterContext);
  const filteredQuestionListContext = useContext(FilteredQuestionListContext);

  const { colorPaletteState, updateColorPaletteState } = colorPaletteContext;

  // const [colorList, setColorList] = useState([
  //   {
  //     color: "",
  //     showHexColorPicker: false,
  //   },
  // ]);

  const [colorList, setColorList] = useState(
    colorPaletteState.map((color, index) => ({
      color: color,
      showHexColorPicker: false,
    }))
  );

  const [show, setShow] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseFilterModal = () => setShowFilters(false);
  const handleFiltersShow = () => setShowFilters(true);

  const handleColorAdd = () => {
    setColorList([...colorList, { color: "" }]);
  };

  const handleColorRemove = (index) => {
    const list = [...colorList];
    list.splice(index, 1);
    setColorList(list);
  };

  const handleColorChange = (index, color) => {
    setColorList(
      colorList.map((item, index1) =>
        index === index1 ? { ...item, color } : item
      )
    );
  };

  const handleFocus = (index) => {
    setColorList(
      colorList.map((item, index1) =>
        index === index1 ? { ...item, showHexColorPicker: true } : item
      )
    );
  };

  const handleBlur = (index) => {
    setColorList(
      colorList.map((item, index1) =>
        index === index1 ? { ...item, showHexColorPicker: false } : item
      )
    );
  };

  const handleSaveChanges = () => {
    if (colorList.some((item) => item.color === "")) {
      toast.error("Please ensure to add color entry(ies)!", {
        autoClose: 2000,
        hideProgressBar: true,
      });

      return;
    }

    updateColorPaletteState(colorList.map((item) => item.color));
    handleClose();
  };

  const handleRevert = () => {
    updateColorPaletteState(COLORS_RYGU);
    setColorList(
      COLORS_RYGU.map((color, index) => ({
        color: color,
        showHexColorPicker: false,
      }))
    );
    handleClose();
  };

  //#region Slide 1.1
  const satisfactionLevelPieChart = [
    {
      name: "Satisfaction Level",
      labels: SATISFACTION_LEVEL_LIST.map((item) => item.value),
      values: getCountryData(),
    },
  ];

  function getCountryCount(idVal, arr = []) {
    let finalArray = arr;

    if (filterContext.state.countryFilterValue) {
      finalArray = finalArray.filter(
        (item) => item.Q0 === filterContext.state.countryFilterValue
      );
    }

    return (
      (finalArray.filter((obj) => obj.Q1 === idVal).length /
        finalArray.length) *
      100
    ).toFixed(0);
  }

  function getCountryData() {
    return SATISFACTION_LEVEL_LIST.map((item, index) =>
      getCountryCount(item.key, SATISFACTION_LEVEL_DATA)
    );
  }
  //#endregion

  //#region Slide 1.2
  const salesByRegionDoughnutChart = [
    {
      name: "Sales by Region",
      labels: ["North America", "Europe", "Asia", "South America"],
      values: getSalesByRegionData(SALES_BY_REGION_DATA),
    },
  ];

  function getSalesByRegionData(arr = []) {
    let finalArray = arr;

    if (filterContext.state.countryFilterValue) {
      finalArray = finalArray.filter(
        (item) => item.Q0 === filterContext.state.countryFilterValue
      );
    }

    return finalArray.map((item) => item.Q2);
  }

  //#endregion

  //#region Slide 2

  const dataChartAreaLines = LINE_CHART_LIST.map((item, index) => ({
    name: item.value,
    labels: LINE_CHART_LABELS.map((item) => item.value),
    values: getAveragePercentageForEachOptionLineChart(
      item.realValue,
      LINE_CHART_BAR_DATA
    ),
  }));

  function getAveragePercentageForEachOptionLineChart(index, resultArray = []) {
    if (filterContext.state.countryFilterValue) {
      resultArray = resultArray.filter(
        (item) => item.Q0 === filterContext.state.countryFilterValue
      );
    }

    var temp = resultArray.filter((item) => item.Q3 === index);
    if (!temp.length > 0) {
      return [];
    }

    var subset = {
      Q3_1: temp[0].Q3_1,
      Q3_2: temp[0].Q3_2,
      Q3_3: temp[0].Q3_3,
      Q3_4: temp[0].Q3_4,
      Q3_5: temp[0].Q3_5,
    };

    return Object.values(subset).map((item) => Number(item));
  }

  //#endregion

  //#region Slide 4

  const arrDataRegions = RATING_PRODUCT_FEATURE_LIST.map(
    (item, ratingProductFeatureIndex) => ({
      name: item.value,
      labels: RATING_PRODUCT_FEATURE_LABELS.map((item) => item.value),
      values: RATING_PRODUCT_FEATURE_LABELS.map((obj, index) =>
        getAveragePercentageForEachOptionRatingProductFeature(
          obj.key,
          ratingProductFeatureIndex,
          RATING_PRODUCT_FEATURE_BAR_CHART_DATA
        )
      ),
    })
  );

  function getAveragePercentageForEachOptionRatingProductFeature(
    index,
    ratingProductFeatureIndex,
    resultArray = []
  ) {
    if (filterContext.state.countryFilterValue) {
      resultArray = resultArray.filter(
        (item) => item.Q0 === filterContext.state.countryFilterValue
      );
    }

    var test = resultArray.filter(
      (obj) =>
        obj[`Q5_${ratingProductFeatureIndex + 1}`] === "1" && obj.Q5 == index
    );

    if (resultArray.filter((obj) => obj.Q5 == index).length === 0) {
      return 0;
    }

    var temp = (
      (test.length / resultArray.filter((obj) => obj.Q5 == index).length) *
      100
    ).toFixed(0);

    return temp;
  }
  //#endregion

  //#region Slide 6
  const dataChartBar8Series = SOFTWARE_ADOPTION_LIST.map(
    (item, ratingProductFeatureIndex) => ({
      name: item.value,
      labels: SOFTWARE_ADOPTION_LABELS.map((item) => item.value),
      values: SOFTWARE_ADOPTION_LABELS.map((obj, index) =>
        getAveragePercentageForEachOptionStackedBarChart(
          obj.key,
          ratingProductFeatureIndex,
          SOFTWARE_ADOPTION_BAR_CHART_DATA
        )
      ),
    })
  );

  function getAveragePercentageForEachOptionStackedBarChart(
    index,
    softwareAdoptionIndex,
    resultArray = []
  ) {
    if (filterContext.state.countryFilterValue) {
      resultArray = resultArray.filter(
        (item) => item.Q0 === filterContext.state.countryFilterValue
      );
    }

    var test = resultArray.filter(
      (obj) =>
        obj[`Q7_${softwareAdoptionIndex + 1}`] === "1" && obj.Q7 === index
    );

    if (!test.length > 0) {
      return 0;
    }

    var temp = (
      (test.length / resultArray.filter((obj) => obj.Q7 === index).length) *
      100
    ).toFixed(0);

    return temp;
  }
  //#endregion

  //#region Slide 7.1
  const manufacturerPreferenceBarChartQuestion7_1Data = [
    {
      name: "Region 1",
      labels: [
        "Very likely",
        "Likely",
        "Somewhat likely",
        "Neutral",
        "Somewhat unlikely",
        "Unlikely",
        "Very unlikely",
      ],
      values: getManufacturerPreferenceForEachOptionBarChart(
        MANUFACTURER_PREFERENCE_BAR_CHART_DATA
      ),
    },
  ];

  function getManufacturerPreferenceForEachOptionBarChart(resultArray = []) {
    if (filterContext.state.countryFilterValue) {
      resultArray = resultArray.filter(
        (item) => item.Q0 === filterContext.state.countryFilterValue
      );
    }

    var test = MANUFACTURER_PERFERENCE_Q8_LIST.map(
      (item, index) =>
        resultArray.filter((obj) => obj.Q8 === item.realValue).length
    );

    if (resultArray.length === 0) {
      return [0, 0, 0, 0, 0, 0, 0];
    }

    var temp = test.map((item, index) => {
      return ((Number(item) / resultArray.length) * 100).toFixed(0);
    });

    return temp;
  }
  //#endregion

  //#region Slide 7.2
  const manufacturerPreferenceBarChartQuestion7_2Data = [
    {
      name: "Region 2",
      labels: [
        "Very likely",
        "Likely",
        "Somewhat likely",
        "Neutral",
        "Somewhat unlikely",
        "Unlikely",
        "Very unlikely",
      ],
      values: getManufacturerPreferenceForEachOptionBarChartQuestion7_2(
        SWITCHING_MANUFACTURER_BAR_CHART_DATA
      ),
    },
  ];

  function getManufacturerPreferenceForEachOptionBarChartQuestion7_2(
    resultArray = []
  ) {
    if (filterContext.state.countryFilterValue) {
      resultArray = resultArray.filter(
        (item) => item.Q0 === filterContext.state.countryFilterValue
      );
    }

    var test = SWITCHING_MANUFACTURER_Q9_LIST.map(
      (item, index) =>
        resultArray.filter((obj) => obj.Q9 === item.realValue).length
    );

    if (resultArray.length === 0) {
      return [0, 0, 0, 0, 0, 0, 0];
    }

    return test.map((item, index) => {
      return ((Number(item) / resultArray.length) * 100).toFixed(0);
    });
  }

  //#endregion
  function getSlide1(pptx) {
    let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });

    slide.addChart(pptx.ChartType.pie, satisfactionLevelPieChart, {
      x: 0.5,
      y: 0.2,
      w: "80%",
      h: "60%",
      //
      chartColors: colorPaletteState,
      dataBorder: { pt: "1", color: "F1F1F1" },
      //
      showLegend: true,
      legendPos: "b",
      legendPos: "b",
      legendFontFace: `${LEGEND_FONT_FACE}`,
      //
      showPercent: true,
      //
      dataLabelColor: "F1F1F1",
      dataLabelFontSize: `${DATA_LABEL_FONT_SIZE}`,
      dataLabelPosition: "bestFit",
      //
      showTitle: true,
      title: `${QUESTION_1}`,
      titleFontSize: `${TITLE_FONT_SIZE}`,
      titleFontFace: `${TITLE_FONT_FACE}`,
      //
    });

    let rows = [
      [
        {
          text: "Level of satisfaction",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
            bold: true,
          },
        },
        {
          text: "Submission",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
            bold: true,
          },
        },
        {
          text: "%",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
            bold: true,
          },
        },
      ],
      [
        {
          text: "Very satisfied",
          options: {
            align: "left",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "86",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "43 %",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
      ],
      [
        {
          text: "Satisfied",
          options: {
            align: "left",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "48",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "24 %",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
      ],
      [
        {
          text: "Neutral",
          options: {
            align: "left",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "38",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "19 %",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
      ],
      [
        {
          text: "Dissatisfied",
          options: {
            align: "left",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "28",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "14 %",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
      ],
    ];

    slide.addTable(rows, {
      x: 2.9,
      y: 5.3,
      w: 6,
      rowH: 0.3,
      border: { pt: 1, color: "000000" },
    });

    let objOpts1 = {
      x: 2.9,
      y: 5.1,
      w: "80%",
      rowH: 0.4,
      margin: 0,
      fontSize: `${TITLE_FONT_SIZE}`,
      fontFace: `${TITLE_FONT_FACE}`,
      color: "000000",
    };
    slide.addText(`${CHART_SUMMARY_TITLE}`, objOpts1);
  }

  function getSlide2(pptx) {
    let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });

    slide.addChart(pptx.charts.DOUGHNUT, salesByRegionDoughnutChart, {
      x: 0.5,
      x: 0.5,
      y: 0.6,
      w: "80%",
      h: "80%",
      //
      showValue: true,
      //
      dataLabelColor: "F1F1F1",
      dataLabelFontSize: `${DATA_LABEL_FONT_SIZE}`,
      dataLabelPosition: "bestFit",
      dataBorder: { pt: "1", color: "F1F1F1" },
      dataLabelFormatCode: "\\$ 0,0",
      //
      showLegend: true,
      legendPos: "b",
      legendPos: "b",
      legendFontFace: `${LEGEND_FONT_FACE}`,
      //
      chartColors: colorPaletteState,
      //
      showTitle: true,
      titleFontFace: `${TITLE_FONT_FACE}`,
      titleFontSize: `${TITLE_FONT_SIZE}`,
      title: `${QUESTION_2}`,
    });
  }

  function getSlide3(pptx) {
    let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });

    slide.addChart(pptx.ChartType.line, dataChartAreaLines, {
      x: 0.5,
      y: 0.6,
      w: 12,
      h: "80%",
      //
      showTitle: true,
      title: `${QUESTION_3}`,
      titleFontFace: `${TITLE_FONT_FACE}`,
      titleFontSize: `${TITLE_FONT_SIZE}`,
      //
      showLegend: true,
      legendPos: "b",
      legendFontFace: `${LEGEND_FONT_FACE}`,
      //
      showValAxisTitle: true,
      //catGridLine: { size: 1, color: "666666", style: "dot" },
      valGridLine: { size: 1, color: "666666", style: "solid" },
      valAxisTitle: "(Number of Meals / Per Year)",
      //catAxisTitle: "(Month)",
      valAxisTitleFontSize: 10,
      valAxisTitleFontFace: `${TITLE_FONT_FACE}`,
      catAxisTitleFontFace: `${TITLE_FONT_FACE}`,
      valAxisTitleColor: "3b73b3",
      valAxisLabelFontSize: 8,
      catAxisLabelFontSize: 8,
      chartColors: colorPaletteState,
    });
  }

  function getSlide4(pptx) {
    let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });

    let dataChartAreaLines = [
      {
        name: "Bank Transfer",
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        values: [65, 50, 52, 53, 48, 55, 43, 41, 58, 60, 61, 65],
        // fill: "#F15C01",
      },
      {
        name: "Credit Card",
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        values: [75, 65, 62, 63, 58, 61, 53, 51, 68, 70, 71, 71],
        // fill: "#AEB4BA",
      },
      {
        name: "Debit Card",
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        values: [85, 70, 72, 73, 68, 71, 63, 61, 78, 80, 81, 87],
        // fill: "#15156D",
      },
      {
        name: "Paypal",
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        values: [95, 80, 82, 83, 78, 86, 73, 71, 88, 90, 91, 92],
        // fill: "#15156D",
      },
    ];

    slide.addChart(pptx.charts.AREA, dataChartAreaLines, {
      x: 0.5,
      y: 0.6,
      w: "80%",
      h: "80%",
      //
      showTitle: true,
      title: `${QUESTION_4}`,
      titleFontFace: `${TITLE_FONT_FACE}`,
      titleFontSize: `${TITLE_FONT_SIZE}`,
      //
      showLegend: true,
      legendPos: "b",
      legendFontFace: `${LEGEND_FONT_FACE}`,
      //
      showValAxisTitle: true,
      showValue: false,
      //catGridLine: { size: 1, color: "666666", style: "dot" },
      valGridLine: { size: 1, color: "666666", style: "solid" },
      valAxisTitle: "(Number of Sales)",
      //catAxisTitle: "(Month)",
      // valAxisMaxVal: 100,
      // valAxisMinVal: 0,

      // vAxis: { minValue: 0, maxValue: 100 },
      valAxisTitleFontSize: 10,
      valAxisTitleFontFace: `${TITLE_FONT_FACE}`,
      catAxisTitleFontFace: `${TITLE_FONT_FACE}`,
      valAxisTitleColor: "3b73b3",
      valAxisLabelFontSize: 8,
      catAxisLabelFontSize: 8,
      chartColors: ["F15C01", "AEB4BA", "15156D", "0AACC2"],
      chartColorsOpacity: 25,
      dataBorder: { pt: 2, color: "FFFFFF" },
      barGrouping: "stacked",
      //       const COLOR_ORANGE = "#F15C01";F15C01
      // const COLORS_X = "#AEB4BA";
      // const COLORS_Y = "#15156D";
      // const COLORS_Z = "#0AACC2";
    });
  }

  function getSlide5(pptx) {
    // SLIDE 3: Bar Chart Options: Axis, DataLabel, Grid
    let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });

    // TOP-RIGHT: V/col
    let optsChartBar2 = {
      x: 0.5,
      y: 0.3,
      w: "80%",
      h: "60%",
      barDir: "col",
      //plotArea: { fill: { color: "E1F1FF" } },
      chartColors: colorPaletteState,
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelColor: "696969",
      dataLabelFontFace: `${DATA_LABEL_FONT_FACE}`,
      dataLabelFontSize: `${DATA_LABEL_FONT_SIZE}`,
      dataLabelPosition: "outEnd",
      //dataLabelFormatCode: "#",
      showValue: true,
      dataLabelFormatCode: "0\\%",
      dataTableFormatCode: "0\\%",
      //catAxisHidden: true,
      //catGridLine: { style: "none" },
      //valAxisHidden: true,
      valAxisDisplayUnitLabel: true,
      //valGridLine: { style: "none" },
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      //catGridLine: { size: 1, color: "666666", style: "dot" },
      //valGridLine: { size: 1, color: "666666", style: "dot" },
      valAxisTitle: "(Product feature Rating)",
      valAxistitleFontFace: `${TITLE_FONT_FACE}`,
      valAxisTitleColor: "3b73b3",

      showLegend: true,
      legendPos: "b",
      showTitle: true,
      titleFontFace: `${TITLE_FONT_FACE}`,
      titleFontSize: `${TITLE_FONT_SIZE}`,
      showValAxisTitle: true,
      title: `${QUESTION_5}`,
      valAxisLabelFontSize: 8,
      catAxisLabelFontSize: 8,
    };

    slide.addChart(pptx.charts.BAR, arrDataRegions, optsChartBar2);

    let rows = [
      [
        {
          text: "",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
            bold: true,
          },
        },
        {
          text: "User-friendliness",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
            bold: true,
            colspan: 2,
          },
        },
        {
          text: "Price",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
            bold: true,
            colspan: 2,
          },
        },
        {
          text: "Performance",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
            bold: true,
            colspan: 2,
          },
        },
      ],
      [
        {
          text: "Rating",
          options: {
            align: "left",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "Submission",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "%",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "Submission",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "%",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "Submission",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "%",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
      ],
      [
        {
          text: "Excellent",
          options: {
            align: "left",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "78",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "39 %",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "46",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "23 %",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "36",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "18 %",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
      ],
      [
        {
          text: "Good",
          options: {
            align: "left",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "78",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "39 %",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "36",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "18 %",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "110",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "55 %",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
      ],
      [
        {
          text: "Fair",
          options: {
            align: "left",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "22",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "11 %",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "90",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "45 %",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "18",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "9 %",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
      ],
      [
        {
          text: "Poor",
          options: {
            align: "left",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "22",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "11 %",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "28",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "14 %",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "36",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "18 %",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
      ],
    ];

    slide.addTable(rows, {
      x: 0.6,
      y: 5.0,
      w: "80%",
      rowH: 0.4,
      border: { pt: 1, color: "000000" },
    });

    let objOpts1 = {
      x: 0.7,
      y: 4.7,
      w: 4,
      h: 0.3,
      margin: 0,
      fontSize: `${TITLE_FONT_SIZE}`,
      fontFace: `${TITLE_FONT_FACE}`,
      color: "000000",
    };

    slide.addText(`${CHART_SUMMARY_TITLE}`, objOpts1);
  }

  function getSlide6(pptx) {
    // SLIDE 4: Bar Chart Options: Axis, DataLabel, Grid
    let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });

    const labels8Series = [
      "Question 1",
      "Question 2",
      "Question 3",
      "Question 4",
      "Question 5",
      "Question 6",
      "Question 7",
      "Question 8",
    ];

    let arrDataRegions = [
      {
        name: "1-I don't anticipate this impact",
        labels: labels8Series,
        values: [7, 3, 6, 6, 4, 5, 4, 4],
      },
      {
        name: "2",
        labels: labels8Series,
        values: [6, 14, 12, 9, 10, 10, 10, 14],
      },
      {
        name: "3",
        labels: labels8Series,
        values: [15, 12, 11, 11, 11, 10, 17, 10],
      },
      {
        name: "4",
        labels: labels8Series,
        values: [17, 14, 13, 15, 16, 16, 17, 11],
      },
      {
        name: "5",
        labels: labels8Series,
        values: [31, 27, 22, 24, 24, 26, 18, 25],
      },
      {
        name: "6",
        labels: labels8Series,
        values: [21, 22, 27, 26, 24, 24, 24, 29],
      },
      {
        name: "7-I anticipate this impact",
        labels: labels8Series,
        values: [3, 8, 9, 9, 11, 10, 10, 7],
      },
    ];

    let optsChartBar2 = {
      x: 0,
      y: 0.1,
      w: "100%",
      h: "55%",
      // bar direction
      barDir: "col",
      //
      chartColors: colorPaletteState,
      //
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelColor: "696969",
      dataLabelFontFace: `${DATA_LABEL_FONT_FACE}`,
      dataLabelFontSize: `${DATA_LABEL_FONT_SIZE}`,
      dataLabelPosition: "outEnd",
      dataLabelFormatCode: "0\\%",
      dataTableFormatCode: "0\\%",
      //
      showValue: true,
      //
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      valAxisDisplayUnitLabel: true,
      showValAxisTitle: true,
      valAxisTitle: "(Product Feature Rating)",
      valAxistitleFontFace: `${TITLE_FONT_FACE}`,
      valAxisTitleColor: "3b73b3",
      valAxisLabelFontSize: 8,
      catAxisLabelFontSize: 8,
      //
      showLegend: true,
      legendPos: "b",
      //
      showTitle: true,
      titleFontFace: `${TITLE_FONT_FACE}`,
      titleFontSize: `${TITLE_FONT_SIZE}`,
      title: `${QUESTION_6}`,
      dataLabelPosition: "bestFit",
    };

    slide.addChart(pptx.charts.BAR, arrDataRegions, optsChartBar2);

    let rows = [
      [
        {
          text: "",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
            bold: true,
          },
        },
        {
          text: "Question1",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
            bold: true,
            colspan: 2,
          },
        },
        {
          text: "Question2",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
            bold: true,
            colspan: 2,
          },
        },
        {
          text: "Question3",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
            bold: true,
            colspan: 2,
          },
        },
        {
          text: "Question4",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
            bold: true,
            colspan: 2,
          },
        },
        {
          text: "Question5",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
            bold: true,
            colspan: 2,
          },
        },
        {
          text: "Question6",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
            bold: true,
            colspan: 2,
          },
        },
        {
          text: "Question7",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
            bold: true,
            colspan: 2,
          },
        },
        {
          text: "Question8",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
            bold: true,
            colspan: 2,
          },
        },
      ],
      [
        {
          text: "Rating",
          options: {
            align: "left",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "Submission",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "%",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "Submission",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "%",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "Submission",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "%",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "Submission",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "%",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "Submission",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "%",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "Submission",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "%",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "Submission",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "%",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "Submission",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "%",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
      ],
      [
        {
          text: "1",
          options: {
            align: "left",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "14",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "7 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "6",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "3 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "12",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "6 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "12",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "6 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "8",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "4 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "10",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "5 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "8",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "4 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "8",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "4 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
      ],
      [
        {
          text: "2",
          options: {
            align: "left",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "12",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "6 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "28",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "14 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "24",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "12 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "18",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "9 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "20",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "10 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "20",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "10 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "20",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "10 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "28",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "14 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
      ],
      [
        {
          text: "3",
          options: {
            align: "left",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "30",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "15 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "24",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "12 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "22",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "11 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "22",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "11 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "22",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "11 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "20",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "10 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "34",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "17 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "20",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "10 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
      ],
      [
        {
          text: "4",
          options: {
            align: "left",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "34",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "17 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "28",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "14 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "26",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "13 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "30",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "15 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "32",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "16 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "32",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "16 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "34",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "17 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "22",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "11 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
      ],
      [
        {
          text: "5",
          options: {
            align: "left",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "62",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "31 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "54",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "27 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "44",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "22 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "48",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "24 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "48",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "24 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "52",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "26 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "36",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "18 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "50",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "25 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
      ],
      [
        {
          text: "6",
          options: {
            align: "left",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "42",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "21 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "44",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "22 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "54",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "27 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "52",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "26 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "50",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "25 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "48",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "24 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "48",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "24 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "58",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "29 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
      ],
      [
        {
          text: "7",
          options: {
            align: "left",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "6",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "3 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "16",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "8 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "18",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "9 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "18",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "9 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "22",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "11 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "20",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "10 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "20",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "10 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "14",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "7 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
      ],
    ];

    slide.addTable(rows, {
      x: 0.4,
      y: 4.3,
      w: "95%",
      rowH: 0.2,
      border: { pt: 1, color: "000000" },
    });
    let objOpts1 = {
      x: 0.4,
      y: 3.9,
      w: 4,
      h: 0.3,
      margin: 0,
      fontSize: `${TITLE_FONT_SIZE}`,
      fontFace: `${TITLE_FONT_FACE}`,
      color: "000000",
    };
    slide.addText(`${CHART_SUMMARY_TITLE}`, objOpts1);
  }

  function getSlide7(pptx) {
    let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });

    // const labels8Series = [
    //   "Software A",
    //   "Software B",
    //   "Software C",
    //   "Software D",
    // ];

    // const dataChartBar8Series = [
    //   {
    //     name: "Not Started",
    //     labels: labels8Series,
    //     values: [20, 10, 30, 70],
    //   },
    //   {
    //     name: "Adoption In-Progress",
    //     labels: labels8Series,
    //     values: [50, 20, 20, 20],
    //   },
    //   {
    //     name: "Full Adoption",
    //     labels: labels8Series,
    //     values: [30, 70, 50, 10],
    //   },
    // ];

    let optsChart = {
      x: 0.5,
      y: 0,
      w: "90%",
      h: "65%",
      barDir: "col",
      barGrouping: "stacked",
      chartColors: colorPaletteState,
      invertedColors: ["C0504D"],
      showLegend: true,
      legendPos: "b",
      showValue: true,
      dataLabelColor: "F1F1F1",
      dataLabelFontFace: `${DATA_LABEL_FONT_FACE}`,
      dataLabelFontSize: `${DATA_LABEL_FONT_SIZE}`,
      dataLabelPosition: "center",
      //
      showTitle: true,
      titleFontFace: `${TITLE_FONT_FACE}`,
      titleFontSize: `${TITLE_FONT_SIZE}`,
      dataLabelFormatCode: "0\\%",
      dataTableFormatCode: "0\\%",
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      title: `${QUESTION_7}`,
      catAxisTitleFontSize: 8,
      valAxisLabelFontSize: 8,
      catAxisLabelFontSize: 8,
    };

    slide.addChart(pptx.charts.BAR, dataChartBar8Series, optsChart);

    let rows = [
      [
        {
          text: "",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
            bold: true,
          },
        },
        {
          text: "Software A",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
            bold: true,
            colspan: 2,
          },
        },
        {
          text: "Software B",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
            bold: true,
            colspan: 2,
          },
        },
        {
          text: "Software C",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
            bold: true,
            colspan: 2,
          },
        },
        {
          text: "Software D",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
            bold: true,
            colspan: 2,
          },
        },
      ],
      [
        {
          text: "Rating",
          options: {
            align: "left",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "Submission",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "%",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "Submission",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "%",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "Submission",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "%",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "Submission",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "%",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
      ],
      [
        {
          text: "Not started",
          options: {
            align: "left",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "66",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "33 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "28",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "14 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "114",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "57 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "112",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "56 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
      ],
      [
        {
          text: "Adoption In-Progress",
          options: {
            align: "left",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "34",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "17 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "114",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "57 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "58",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "29 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "44",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "22 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
      ],
      [
        {
          text: "Full Adoption",
          options: {
            align: "left",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "100",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "50 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "58",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "29 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "28",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "14 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "44",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "22 %",
          options: {
            align: "right",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
      ],
    ];

    slide.addTable(rows, {
      x: 0.7,
      y: 5.1,
      w: "93%",
      rowH: 0,
      border: { pt: 1, color: "000000" },
    });
    let objOpts1 = {
      x: 0.7,
      y: 4.7,
      w: 4,
      h: 0.3,
      margin: 0,
      fontSize: `${TITLE_FONT_SIZE}`,
      fontFace: `${TITLE_FONT_FACE}`,
      color: "000000",
    };
    slide.addText(`${CHART_SUMMARY_TITLE}`, objOpts1);
  }

  function getSlide8(pptx) {
    let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });

    // TOP-left: V/col
    let optsChartBar1 = {
      x: 0.5,
      y: 0.6,
      w: 6,
      h: "80%",
      barDir: "col",
      //plotArea: { fill: { color: "E1F1FF" } },
      chartColors: [colorPaletteState[0]],
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelColor: "696969",
      dataLabelFontFace: `${DATA_LABEL_FONT_FACE}`,
      dataLabelFontSize: `${DATA_LABEL_FONT_SIZE}`,
      dataLabelPosition: "outEnd",
      //dataLabelFormatCode: "#%",
      showValue: true,
      //showCatAxisTitle: true,
      //catAxisHidden: true,
      catGridLine: { style: "none" },
      //valAxisHidden: true,
      valAxisDisplayUnitLabel: true,
      valGridLine: { style: "none" },
      dataLabelFormatCode: "0\\%",
      dataTableFormatCode: "0\\%",
      //catAxisLabelRotate: 90,
      //catAxisOrientation: "maxMin",
      //valAxisOrientation: "maxMin",
      showLegend: false,
      legendPos: "b",
      showTitle: true,
      titleFontFace: `${TITLE_FONT_FACE}`,
      titleFontSize: `${TITLE_FONT_SIZE}`,
      title: `${QUESTION_8}`,
      valAxisLabelFontSize: 8,
      catAxisLabelFontSize: 8,
      //catGridLine: { size: 1, color: "666666", style: "dot" },
      //valGridLine: { size: 1, color: "666666", style: "dot" },
    };

    slide.addChart(
      pptx.charts.BAR,
      manufacturerPreferenceBarChartQuestion7_1Data,
      optsChartBar1
    );
  }

  function getSlide9(pptx) {
    let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });

    let optsChartBar2 = {
      x: 6.8,
      y: 0.1,
      w: 6,
      h: "60%",
      barDir: "col",
      //plotArea: { fill: { color: "E1F1FF" } },
      dataLabelFormatCode: "0\\%",
      dataTableFormatCode: "0\\%",
      chartColors: [colorPaletteState[0]],
      dataBorder: { pt: 1, color: "F1F1F1" },
      dataLabelColor: "696969",
      dataLabelFontFace: `${DATA_LABEL_FONT_FACE}`,
      dataLabelFontSize: `${DATA_LABEL_FONT_SIZE}`,
      dataLabelPosition: "outEnd",
      //dataLabelFormatCode: "#%",
      showValue: true,
      //showCatAxisTitle: true,
      //catAxisHidden: true,
      catGridLine: { style: "none" },
      //valAxisHidden: true,
      valAxisDisplayUnitLabel: true,
      valGridLine: { style: "none" },
      //catAxisLabelRotate: -90,
      //catAxisOrientation: "maxMin",
      //valAxisOrientation: "maxMin",
      //showLegend: false,
      //legendPos: "b",
      showTitle: true,
      titleFontFace: `${TITLE_FONT_FACE}`,
      titleFontSize: `${TITLE_FONT_SIZE}`,
      title: `${QUESTION_9}`,
      valAxisLabelFontSize: 8,
      catAxisLabelFontSize: 8,
      //catGridLine: { size: 1, color: "666666", style: "dot" },
      //valGridLine: { size: 1, color: "666666", style: "dot" },
    };

    let rows = [
      [
        {
          text: "Level of satisfaction",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
            bold: true,
          },
        },
        {
          text: "Submission",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
            bold: true,
          },
        },
        {
          text: "%",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
            bold: true,
          },
        },
      ],
      [
        {
          text: "Very likely",
          options: {
            align: "left",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "72",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "36 %",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
      ],
      [
        {
          text: "Likely",
          options: {
            align: "left",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "46",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "23 %",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
      ],
      [
        {
          text: "Somewhat likely",
          options: {
            align: "left",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "18",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "9 %",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
      ],
      [
        {
          text: "Neutral",
          options: {
            align: "left",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "18",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "9 %",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
      ],
      [
        {
          text: "Somewhat unlikely",
          options: {
            align: "left",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "10",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "5 %",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
      ],
      [
        {
          text: "Unlikely",
          options: {
            align: "left",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "10",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "5 %",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
      ],
      [
        {
          text: "Very Unlikely",
          options: {
            align: "left",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "28",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "14 %",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
      ],
    ];

    slide.addTable(rows, {
      x: 0.5,
      y: 5,
      w: 6,
      rowH: 0.2,
      border: { pt: 1, color: "000000" },
    });
    let objOpts1 = {
      x: 0.5,
      y: 4.7,
      w: 4,
      h: 0.3,
      margin: 0,
      fontSize: `${TITLE_FONT_SIZE}`,
      fontFace: `${TITLE_FONT_FACE}`,
      color: "000000",
    };

    slide.addText(`${CHART_SUMMARY_TITLE}`, objOpts1);

    rows = [
      [
        {
          text: "Level of satisfaction",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
            bold: true,
          },
        },
        {
          text: "Submission",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
            bold: true,
          },
        },
        {
          text: "%",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
            bold: true,
          },
        },
      ],
      [
        {
          text: "Very likely",
          options: {
            align: "left",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "36",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "18 %",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
      ],
      [
        {
          text: "Likely",
          options: {
            align: "left",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "72",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "36 %",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
      ],
      [
        {
          text: "Somewhat likely",
          options: {
            align: "left",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "28",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "14 %",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
      ],
      [
        {
          text: "Neutral",
          options: {
            align: "left",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "18",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "9 %",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
      ],
      [
        {
          text: "Somewhat unlikely",
          options: {
            align: "left",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "18",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "9 %",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
      ],
      [
        {
          text: "Unlikely",
          options: {
            align: "left",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "10",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
        {
          text: "5 %",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#E5E4E2" },
          },
        },
      ],
      [
        {
          text: "Very Unlikely",
          options: {
            align: "left",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "18",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
        {
          text: "9 %",
          options: {
            align: "center",
            fontFace: "Arial",
            fill: { color: "#D3D3D3" },
          },
        },
      ],
    ];

    slide.addTable(rows, {
      x: 7,
      y: 5,
      w: 6,
      rowH: 0.2,
      border: { pt: 1, color: "000000" },
    });
    let objOpts2 = {
      x: 7,
      y: 4.7,
      w: 4,
      h: 0.3,
      margin: 0,
      fontSize: `${TITLE_FONT_SIZE}`,
      fontFace: `${TITLE_FONT_FACE}`,
      color: "000000",
    };
    slide.addText(`${CHART_SUMMARY_TITLE}`, objOpts2);

    slide.addChart(
      pptx.charts.BAR,
      manufacturerPreferenceBarChartQuestion7_2Data,
      optsChartBar2
    );
  }

  function handleLogout() {
    Auth.signOut().then(() => console.log("User signed out successfully"));
  }

  function handleClick() {
    let pptx = new pptxgen();
    pptx.layout = "LAYOUT_WIDE";

    pptx.defineSlideMaster({
      title: "MASTER_SLIDE",
      background: { fill: "F1F1F1" },
      margin: [0.5, 0.25, 1.0, 0.25],
      objects: [
        {
          // rect: {
          //   x: 0.0,
          //   y: 6.9,
          //   w: "100%",
          //   h: 0.6,
          //   //fill: { color: "003b75" },
          // },
          rect: {
            x: 0.0,
            y: 6.9,
            w: "100%",
            h: 0.6,
            //fill: { color: "white" },
          },
        },
        {
          image: {
            x: 12.8,
            y: 7.0,
            w: 0.44,
            h: 0.44,
            //data: STARLABS_LOGO_SM,
          },
        },
        {
          text: {
            options: {
              x: 0.63,
              y: 7.26,
              w: "100%",
              //h: 0.6,
              //align: "center",
              //valign: "middle",
              color: "#AEB4BA",
              fontSize: 8,
            },
            text: "CONFIDENTIAL, @ 2024 Demo version only",
          },
        },
      ],
      slideNumber: {
        x: 0.33,
        y: 7.1,
        //h: 0.6,
        color: "black",
        fontFace: "Arial",
        fontSize: 10,
        //align: "center",
      },
    });

    getSlide1(pptx);
    getSlide2(pptx);
    getSlide3(pptx);
    getSlide4(pptx);
    getSlide5(pptx);
    getSlide6(pptx);
    getSlide7(pptx);
    getSlide8(pptx);

    (filteredQuestionListContext.state.allQuestions ||
      filteredQuestionListContext.state.filteredQuestions.includes("Q8")) &&
      getSlide8(pptx);

    (filteredQuestionListContext.state.allQuestions ||
      filteredQuestionListContext.state.filteredQuestions.includes("Q9")) &&
      getSlide9(pptx);

    var d = new Date();

    pptx.writeFile({
      fileName:
        "Report_" +
        d.getTime() +
        "/" +
        d.getDate() +
        "/" +
        d.getMonth() +
        "/" +
        d.getFullYear() +
        ".pptx",
    });
  }

  return (
    <>
      <Navbar expand="lg" className="navbar-container justify-content-between">
        <Container fluid>
          <Navbar.Brand href="https://www.tehrihills.com/">
            <img
              src="./tehri-hills.png"
              alt="my logo img"
              height="75"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll" className="justify-content-end">
            <Nav>
              <Nav.Link
                onClick={handleFiltersShow}
                style={{ cursor: "pointer" }}
                role="navigation"
              >
                <IconContext.Provider value={{ color: "grey", size: 20 }}>
                  <div className="d-flex">
                    <span
                      style={{
                        marginRight: "10px",
                        verticalAlign: "middle",
                        color: "grey",
                        textTransform: "none",
                      }}
                    >
                      Filters
                    </span>
                    <FaFilter style={{ color: "grey" }} />
                  </div>
                </IconContext.Provider>
              </Nav.Link>
              <Nav.Link onClick={handleShow} style={{ cursor: "pointer" }}>
                <IconContext.Provider value={{ color: "grey", size: 20 }}>
                  <div className="d-flex">
                    <span
                      style={{
                        marginRight: "10px",
                        verticalAlign: "middle",
                        color: "grey",
                        textTransform: "none",
                      }}
                    >
                      Color Palette
                    </span>
                    <CgColorPicker style={{ color: "grey" }} />
                  </div>
                </IconContext.Provider>
              </Nav.Link>
              <Nav.Link onClick={handleClick} style={{ cursor: "pointer" }}>
                <IconContext.Provider value={{ color: "white", size: 20 }}>
                  <div className="d-flex">
                    <span
                      style={{
                        marginRight: "10px",
                        verticalAlign: "middle",
                        color: "grey",
                        textTransform: "none",
                      }}
                    >
                      Export
                    </span>
                    <FiDownload style={{ color: "grey" }} />
                  </div>
                </IconContext.Provider>
              </Nav.Link>
              <Nav.Link onClick={handleLogout} style={{ cursor: "pointer" }}>
                <IconContext.Provider value={{ color: "grey", size: 20 }}>
                  <div className="d-flex">
                    <span
                      style={{
                        marginRight: "10px",
                        verticalAlign: "middle",
                        color: "grey",
                        textTransform: "none",
                      }}
                    >
                      Logout
                    </span>
                    <FiLogOut />
                  </div>
                </IconContext.Provider>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <ColorPickerModal
        show={show}
        onClose={handleClose}
        onSaveChanges={handleSaveChanges}
        colorList={colorList}
        onColorChange={handleColorChange}
        onColorRemove={handleColorRemove}
        onColorAdd={handleColorAdd}
        onFocusChange={handleFocus}
        onBlurChange={handleBlur}
        onRevertChanges={handleRevert}
      />

      <FilterModal show={showFilters} onClose={handleCloseFilterModal} />
      <ToastContainer />
    </>
  );
}
